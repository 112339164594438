<template>
  <div style="height: 100%">
    <gantt-elastic v-if="tasks.length > 0" style="height: 100%" :tasks="tasks" :options="options">
      <template slot="header">
        <div>
          <el-button type="text" @click="addRootRecord()" size="small">{{ $locale.main.button.add }}</el-button>
          <el-button-group style="float: right"><el-button @click="zoomChart('plus')" size="mini" icon="el-icon-minus"></el-button><el-button @click="zoomChart('minus')" size="mini" icon="el-icon-plus"></el-button></el-button-group>
        </div>
      </template>
    </gantt-elastic>
    <div v-else style="height: 100%; width: 100%;">
      <el-button type="text" @click="addRootRecord()" size="small">{{ $locale.main.button.add }}</el-button>
      <div style="height: calc(100% - 32px); width: 100%; background-color: #f4f4f5;display: flex;
      align-items: center;
      justify-content: center;">
      <div style="font-weight: 700;font-size: 13px;color: #909399;">{{$locale.main.message.no_data}}</div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import GanttElastic from 'gantt-elastic'
import mixin from '../mixins'

export default {
  name: 'a-gantt',
  mixins: [mixin],
  inject: ['getRegistryRecordId', 'openRegistryCard', 'getModel'],
  components: {
    ganttElastic: GanttElastic
  },
  props: {
    pluginName: {
      type: String,
      editor: 'Plugin'
    },
    height: {
      type: String,
      description: 'Высота (px)',
      default: '300'
    },
    outerXrefId: {
      type: String,
      description: 'По внешней ссылке (attr_N_)'
    },
    parentXrefId: {
      type: String,
      description: 'Ссылка на родителя (attr_N_)'
    },
    positionAddColumn: {
      type: String,
      description: 'Позиция столбца добавления (с 0)',
      default: '0'
    },
    stepDuration: {
      editor: 'Select',
      description: 'Шаг диаграммы',
      default: 'week',
      options: {
        multiple: false,
        options: [
          { name: 'День', id: 'day' },
          { name: 'Неделя', id: 'week' },
          { name: 'Месяц', id: 'month' }
        ]
      }
    }
  },
  data () {
    return {
      cardId: null,
      tasks: [],
      options: {
        taskMapping: {
          progress: 'percent'
        },
        maxRows: 100,
        maxHeight: this.height,
        minHeight: 20,
        times: {
          timeZoom: 20,
          stepDuration: this.stepDuration
        },
        row: {
          height: 24
        },
        calendar: {
          hour: {
            display: false
          },
          day: {
            display: true
          }
        },
        chart: {
          progress: {
            bar: false
          },
          expander: {
            display: true
          }
        },
        taskList: {
          expander: {
            straight: false
          },
          columns: []
        },
        locale: {
          name: 'ru', // name String
          weekdays: 'Понедельник_Вторник_Среда_Четверг_Пятница_Суббота_Воскресенье'.split('_'), // weekdays Array
          weekdaysShort: 'Пн_Вт_Ср_Чт_Пт_Сб_Вс'.split('_'), // OPTIONAL, short weekdays Array, use first three letters if not provided
          weekdaysMin: 'Пн_Вт_Ср_Чт_Пт_Сб_Вс'.split('_'), // OPTIONAL, min weekdays Array, use first two letters if not provided
          months: 'Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь'.split('_'), // months Array
          monthsShort: 'Янв_Фев_Мар_Апр_Май_Июн_Июл_Авг_Сен_Окт_Ноя_Дек'.split('_'), // OPTIONAL, short months Array, use first three letters if not provided
          ordinal: n => `${n}` // ordinal Function (number) => return number + output
        }
      }
    }
  },
  async created () {
    if (!this.pluginName) {
      return false
    }
    await this.loadData()
  },
  mounted () {
    if (this.stepDuration === 'month' || this.stepDuration === 'week') {
      this.$set(this.options.times, 'timeZoom', 24)
      this.options.calendar.day.display = false
    }
  },
  methods: {
    async getCardData (objectId) {
      let data = await this.$http.get(`${this.$config.api}/interfaceeditor/cards?entity_id=${objectId}`)
      return data.data.find(item => item.is_default)
    },
    zoomChart (type) {
      if (type === 'plus') {
        this.$set(this.options.times, 'timeZoom', this.options.times.timeZoom + 1)
      } else if (type === 'minus') {
        this.$set(this.options.times, 'timeZoom', this.options.times.timeZoom - 1)
      }
    },
    collapse () {
      let records = []
      if (Array.isArray(arguments[0])) {
        records = arguments[0]
      } else if (typeof arguments[0] === 'number' || typeof arguments[0] === 'string') {
        records.push(arguments[0])
      }
      this.tasks.forEach((item) => {
        if ((records.length > 0 && records.includes(item.id)) || records.length === 0) {
          item.collapsed = true
        }
      })
    },
    expand () {
      let records = []
      if (Array.isArray(arguments[0])) {
        records = arguments[0]
      } else if (typeof arguments[0] === 'number' || typeof arguments[0] === 'string') {
        records.push(arguments[0])
      }
      this.tasks.forEach((item) => {
        if ((records.length > 0 && records.includes(item.id)) || records.length === 0) {
          item.collapsed = false
        }
      })
    },
    addRecord (recordId) {
      this.loadData()
    },
    updateRecord (recordId) {
      this.loadData()
    },
    async loadData (buildColumns = true) {
      let data = await this.$http.get(`${this.$config.api}/registryservice/plugins/execute/${this.pluginName}?recordId=${this.getRegistryRecordId() || 0}`)
      this.tasks = data.data.data.map(item => {
        item.start = item.start_date ? new Date(item.start_date).getTime() : this.getDate(0)
        item.duration = item.end_date ? (new Date(item.end_date).getTime() - new Date(item.start_date).getTime()) : 0
        item.parentId = parseInt(item.parent_id) || null
        item.type = item.parentId ? 'task' : 'project'
        item.percent = 100
        item.label = item.text
        item.collapsed = false
        item.style = {
          base: {
            fill: item.color || '#0287D0',
            stroke: item.color || '#0077C0'
          }
        }
        return item
      }).filter(n => n)
      if (buildColumns) {
        this.$set(this.options.taskList, 'columns', this.prepareInteractiveColumns(data.data.columns))
      }
    },
    async addRootRecord () {
      if (!this.outerXrefId && this.outerXrefId.match(/attr_[0-9]+_/i)) {
        return false
      }
      let data = await this.$http.get(`${this.$config.api}/objecteditor/entities/${this.outerXrefId.replace(/[^0-9]/g, '')}`)
      let objectId = data.data.data.object_id
      console.log(objectId)
      let card = await this.getCardData(objectId)
      if (card) {
        let initialData = {}
        initialData[this.outerXrefId] = this.getRegistryRecordId()
        this.openRegistryCard({
          registryId: objectId,
          cardId: card.id,
          cardName: card.name,
          recordId: null,
          initialData: initialData,
          registry: this
        })
      }
    },
    getDate (hours) {
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()
      const currentMonth = currentDate.getMonth()
      const currentDay = currentDate.getDate()
      const timeStamp = new Date(currentYear, currentMonth, currentDay, 0, 0, 0).getTime()
      return new Date(timeStamp + hours * 60 * 60 * 1000).getTime()
    },
    prepareInteractiveColumns (columns) {
      let result = columns.map(item => {
        if (item.interactive) {
          let me = this
          item.events = {
            async click ({ data, column }) {
              if (data.id && data.object_id) {
                let cardData = await me.$http.get(`${me.$config.api}/registryservice/registry/${data.object_id}/records/${data.id}/card`)
                let card = cardData.data[0]
                if (card) {
                  me.openRegistryCard({
                    registryId: data.object_id,
                    cardId: card.id,
                    cardName: card.name,
                    recordId: data.id,
                    initialData: {},
                    registry: me
                  })
                }
              }
            }
          }
          item.style = {
            'task-list-item-value-container': {
              'cursor': 'pointer'
            }
          }
        }

        return item
      })
      if (this.parentXrefId) {
        let me = this
        result.splice(this.positionAddColumn, 0, {
          id: 999,
          label: '+',
          width: 40,
          value: task => '+',
          style: {
            'task-list-item-value-container': {
              'cursor': 'pointer'
            }
          },
          events: {
            async click ({ data }) {
              if (data.object_id) {
                let card = await me.getCardData(data.object_id)
                if (card) {
                  let initialData = {}
                  initialData[me.parentXrefId] = data.id
                  initialData[me.outerXrefId] = me.getRegistryRecordId()
                  me.openRegistryCard({
                    registryId: data.object_id,
                    cardId: card.id,
                    cardName: card.name,
                    recordId: null,
                    initialData: initialData,
                    registry: me
                  })
                }
              }
            }
          }
        })
      }

      return result
    }
  }
}
</script>

<style>
.gantt-elastic__task-list-item-value-container {
  padding: 7px 7px 7px;
  background: white;
}
</style>
